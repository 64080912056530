$form-floating-height: 4rem;
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons';

// Include breakpoints for width utility. For some reason, the recommended way
// to modify utilities doesn't seem to work, so define them manually. See:
// https://getbootstrap.com/docs/5.3/utilities/api/#modify-utilities
// https://github.com/twbs/bootstrap/issues/26528#issuecomment-1015949847
@each $breakpoint, $breakpoint-value in $container-max-widths {
	@include media-breakpoint-up($breakpoint) {
		@each $size, $value in map-get(map-get($utilities, 'width'), 'values') {
			.w-#{$breakpoint}-#{$size} {
				width: $value !important;
			}
		}
		@each $size, $value in map-get(map-get($utilities, 'height'), 'values') {
			.h-#{$breakpoint}-#{$size} {
				height: $value !important;
			}
		}
	}
}

.modal-header {
	border-bottom: 0 none;
}

.modal-footer {
	border-top: 0 none;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.modal-footer > * {
	align-self: stretch;
}

.modal-content {
	border-radius: 1rem;
}

.modal-title {
	font-size: 2rem;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 0px;
	text-align: center;
}

.form-label {
	margin-bottom: 0;
}

.card-widget {
	min-width: 15rem;
	margin: 0.4rem;
	text-align: center;
}

.card-widget h2 {
	font-weight: bold;
}
.accordion-button {
	background-color: var(--bs-primary-bg-subtle);
}

.authentication-form-container-width {
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 30rem;
	}
}

.line-item-description-form-control {
	@extend .form-control, :disabled;
	overflow: auto;
	max-height: 5em;
	max-height: calc(3lh + 2 * $input-padding-y);
}
