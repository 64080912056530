.list {
	text-align: left;
	max-width: 750px;
	margin: auto;
}

.submit-form,
.edit-form {
	max-width: 300px;
	margin: auto;
}

html,
body {
	height: 100vh;
	font-family: 'Avenir Next', Arial, Helvetica, sans-serif;
	background-color: #f9fafc !important;
}

#root {
	height: 100%;
}

.sidenav {
	background-color: #242529;
}

.footer {
	margin-top: auto;
}

.bg-mine {
	background-color: #ee5022;
	font-weight: 500;
}

.text-center {
	text-align: center;
}

.badgeR {
	display: inline-block;
	padding: 0.35em 0.65em;
	background: #fd663960;
	vertical-align: baseline;
	border-radius: 0.25rem;
	margin: 0 2px;
}

.badgeC {
	display: inline-block;
	padding: 0.35em 0.65em;
	font-size: 0.875em;
	font-weight: 500;
	line-height: 1.5em;
	background: #fd663930;
	color: #ee5022;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	margin: 0.125em 0.5em;
}

.badgeActive {
	background: #11bd2226;
	color: #11bd22;
}

.badgeInActive {
	background: lightgray;
	color: grey;
}

.circle {
	line-height: 45px;
	width: 45px;
	font-size: 18px;
	border-radius: 32px;
	padding: 0;
	background: #1b2124;
	color: white;
	display: inline-block;
	text-align: center;
}

/* following is the dropdown menu https://codepen.io/danimerida2000/pen/wVXegX */
.dropdown2 {
	position: absolute;
	display: inline-block;
	right: 0.4em;
}

.dropdown-content {
	display: none;
	position: relative;
	margin-top: 60px;
	background-color: #f9f9f9;
	min-width: 160px;
	overflow: auto;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown a:hover {
	background-color: #f1f1f1;
}

#user-action-dropdown::after {
	display: none;
}

.dropdown .shadow {
	box-shadow: 6px 13px 87px 0px #0000002b;
	border-width: 0;
}

#header-breadcrumbs ol.breadcrumb {
	margin: 0;
}

.btn:disabled {
	opacity: 0.35 !important;
}

.form-control-lg {
	font-size: 1rem !important;
}

.btn-secondary {
	background-color: silver !important;
	border-color: silver !important;
}

.text-green {
	color: #23cf35 !important;
}

.text-white {
	color: white;
}

.text-grey {
	color: #8d9091 !important;
}

.bg-orange {
	background-color: #ee5022;
}

.bg-light-green {
	background-color: hwb(126deg 85% 0%) !important;
}

.bg-white {
	background: #fff;
}

.bg-grey {
	background-color: #c4c4c421 !important;
}

.bg-red {
	background-color: #ee5022;
}

.animated-card:hover {
	transform: scale(1.03);
	box-shadow:
		0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
		0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
		0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.animated-card {
	width: 24em;
	transition: all ease 200ms;
}

.card {
	border-radius: 0.6em;
	padding: 1em !important;
	overflow: hidden;
}

.shadowed {
	box-shadow:
		0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
		0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
		0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}

.fit-content {
	max-width: fit-content !important;
}

.info-list li {
	margin-top: 0.5rem;
}

.enable-disable-switch .form-check-input:checked {
	background-color: #dc3545 !important;
}

.enable-disable-switch .form-check-input:not(:checked) {
	background-color: #198754 !important;
}

.position-unset {
	position: unset !important;
}
